import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB v3 Configuration for your WQHD INSTAR MQTTv5 Broker",
  "path": "/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/",
  "dateChanged": "2022-08-15",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version 3 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB v3 Configuration for your WQHD INSTAR MQTTv5 Broker' dateChanged='2022-08-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version 7 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/' locationFR='/fr/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "openhab-v3-configuration-for-your-wqhd-instar-mqttv5-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-v3-configuration-for-your-wqhd-instar-mqttv5-broker",
        "aria-label": "openhab v3 configuration for your wqhd instar mqttv5 broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB v3 Configuration for your WQHD INSTAR MQTTv5 Broker`}</h1>
    <ul>
      <li parentName="ul">{`Part I - MQTT Binding Configuration through the Main UI`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_II/"
        }}>{`Part II - MQTT Binding Configuration through the Configuration Files`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_III/"
        }}>{`Part III - MQTT Binding Rules`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_IV/"
        }}>{`Part IV - MQTT Binding Scripts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_V/"
        }}>{`Part V - Groups and Sitemaps`}</a></li>
    </ul>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my IN-9408 2k+ with the new Version 3 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Start by installing the `}<strong parentName="p">{`MQTT-Binding`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/ae77d/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA3ElEQVQY043OsW6DMBCAYbZUYuDujF0fYGM5jpMgMQSJZmCpFB6ApQtqpW55Iroy5y2rMlQd++2/9CchBO99CIeiKM61caZCIgCgf0i6Td/3xpiX63UcR+ccMwshAJEQBVGe52LztxREibVWbpjZ1bVzrmmatm2NMVprKWUGsHvapWmaZdnvESACYjIMw/l0ivGoNU/TNM+zcy7GqPUzCWGZj6Z8f/u4vd7CIVhrERGI9mWxL8uk6y5xAwDLsqzrKqX03hMREqlcapU/vh73z7sudFVVP8NCsFKs1DfnWyZ6F3UmHwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 230w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 460w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 920w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/24329/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 230w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 460w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 920w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/e4604/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 230w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 460w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 920w", "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/ae77d/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/28c8834de504b5f8bcb5fd2cd4c025cd/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now create a new `}<strong parentName="p">{`Thing`}</strong>{` from the installed binding:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a363bf8cf8a84a175ecea556446efda9/ea64c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.521739130434778%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA4UlEQVQY002My07DMBRE8wdgU+pHbF/fxK84BpJKRU1ZsUQIARVbdvwBQiz4eVACFUezGB1ppkLEcRxLKcMwWIDSRedc27be+xCC976Ushk3uc+L+U9VKx1iCjHF1HEhZa36vs85O+eMMQBgjFFKLf2IMUYIUV3mdNEFLc41X0m2IoRut9f7aZ9SZy0iNohN07QAVin9FyOlIoRUV3ev0/NbuH2Bm6e6TGeUxJhSSgD2GIs4F1gupJRCCEppVT98NIdPcf8uH7/Y7kBPT9YzfIZxzjVycFxbzhhj/Ncz9jP+BoKwKaXcKioMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a363bf8cf8a84a175ecea556446efda9/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 230w", "/en/static/a363bf8cf8a84a175ecea556446efda9/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 460w", "/en/static/a363bf8cf8a84a175ecea556446efda9/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 920w", "/en/static/a363bf8cf8a84a175ecea556446efda9/91422/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 1116w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a363bf8cf8a84a175ecea556446efda9/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 230w", "/en/static/a363bf8cf8a84a175ecea556446efda9/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 460w", "/en/static/a363bf8cf8a84a175ecea556446efda9/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 920w", "/en/static/a363bf8cf8a84a175ecea556446efda9/36c36/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 1116w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a363bf8cf8a84a175ecea556446efda9/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 230w", "/en/static/a363bf8cf8a84a175ecea556446efda9/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 460w", "/en/static/a363bf8cf8a84a175ecea556446efda9/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 920w", "/en/static/a363bf8cf8a84a175ecea556446efda9/ea64c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 1116w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a363bf8cf8a84a175ecea556446efda9/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now add your cameras Broker configuration that you can find/configure in your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`Cameras Web User Interface`}</a>{` - `}<strong parentName="p">{`Note`}</strong>{` that your camera has to be configured to use it's own MQTT Broker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3391daebcb0c0c1fb13f7f47161833b0/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACA0lEQVQoz2WS3W/SUBiHMSZ+rOPLr7BR2h4GdoWCBbopIH6UFqGFQ7/OOV0xjg0Tk85EFxO9Md5454VZTEzczS78S5WWTqdPfhfvzZPf+56chCiK1SWiwJcbvdHw7dmT4PSfPA5OlaMf8P1PqU92W41Op5vL5QAAiWrM9t3yTm/w/MN37903dHyCjk9wmHj+Ov90Jj8187kNAEr5TZoDW39knt9uNSVk6u50ZBmaZWimoVnjQRRTV12ERaGydpVKURmmwHGgtJQrITzPy/KOZTsIk/nB4eHi5f78wEXYcTEixLYdQki9Wk9S6UzqBp1nWLa4ao5l2fP2fN+fzWYYY9u2XdfFGKOQxWJRr91LUul0MkvnGYYBq2ZBEGLZI56HELJDxuOxpmn9fl9RlNFoWKuK62updDJD55lCgUtUYiKZxESyYRhqSF9R9CmqiRJ1fT1sLtA0+/eDrZp93/c8L5Idx3FXOItXR/WadO0KlU5mNzdomgYJQRDOb261ZEI8gollWZE8nZoTCJcxDHdvXhWlS4nL2extmi0xRf5/mZwf7DjOs4Haa+92Ow/kh91HqipJjZu37nBbZXarxILSBVmWZYTQcsUI27K9Odx/g9GLYKgH0Oy22yzHFosAAA4A7oLcbDYhhJMVcGLocBbA1198P/isDj7q8L4sF5hCMbR/f89fBUSvlpO4U68AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3391daebcb0c0c1fb13f7f47161833b0/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 230w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 460w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 920w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/cb214/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3391daebcb0c0c1fb13f7f47161833b0/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 230w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 460w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 920w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/180af/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3391daebcb0c0c1fb13f7f47161833b0/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 230w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 460w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 920w", "/en/static/3391daebcb0c0c1fb13f7f47161833b0/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3391daebcb0c0c1fb13f7f47161833b0/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Back in the `}<strong parentName="p">{`Things`}</strong>{` configuration we can now configure the `}<strong parentName="p">{`MQTT Broker`}</strong>{` by adding our camera's broker:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/f680b/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABDklEQVQY02XJO0/DMBQF4OxI8SNPO37EdtI4TRSnFJJmgAmJoVAELAyIhT/ABmJg5mejpkVC4tMZzrnXM8b0fd80jXMul7K11eIPW1WuLp0tuuXiP49xUdm6srWtlykhNNujB4RQLsXNW/n8za5eKUko/X3NxXONbW3BkpAlIYlDCCGYwT0AcRC1l/R8G1QjPB4hBD4EPkLIW10/TbuXxcUu32yz+gxj5PreORfHSTyLkB+CkwiD44xCInTKcoygx+4+9ONnevtOHr6S8T6AYNxMwzAaU0iZcy64VPuIuQvJKC36STdrBH1vWaquLo0gZZ5xkgRBsDpdD8PYdU4prbU5RCl9iDaFVoozhjH+AYvOMTh8eTWxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 230w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 460w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 920w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/6a1a1/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 230w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 460w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 920w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/17ad2/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 230w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 460w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 920w", "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/f680b/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 1108w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c0a536bf7bd61fb4b48b0fd4b6fb6697/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/57cc7b24023db7d15756c047a42894a1/23725/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABXklEQVQoz2VR22rdMBD0FwRLtiVLK8nWxZIsyeZAWkje2z6UUgiHkF8phZBfT2S3IZBh2Idhh53dbZxz+76XUrZtE5zPAFqbSU1CSCGklCrnnFM2xgKIUzwJAI1UU1xTymVNue8HhHHw4WwFEFqbuCZrndZmnrWU6nRyDgihZk8xOsOGjpNuJEPf937xUqqTxtiUsrOLno1S03+95sIYN7ffr/c/n/zdD/31Gyxbh3FK2XsvpaR0nKZ5nmfGOGP8HQBCKVXN8uGvfXwef/9h1xf65VfX3oQQt20zxhBC8CcghNq2bRGq5hzcnoOdxKKV4CNCaOj7YRjGA5/NhBAALulQdz6vZaxziwchuq5bFh9C4JyzmhUO8rMyxo9DGC2hTo5xLaVYa51zAIBxF2O01gDURwkBhNCTB0ZKKSGE0BqqKaVcLpd1XZ1z4t/k5XhJNX9AVRjj48iEkDmXt9ivPqBA9KmpuQQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57cc7b24023db7d15756c047a42894a1/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 230w", "/en/static/57cc7b24023db7d15756c047a42894a1/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 460w", "/en/static/57cc7b24023db7d15756c047a42894a1/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 920w", "/en/static/57cc7b24023db7d15756c047a42894a1/6c16e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 1105w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/57cc7b24023db7d15756c047a42894a1/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 230w", "/en/static/57cc7b24023db7d15756c047a42894a1/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 460w", "/en/static/57cc7b24023db7d15756c047a42894a1/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 920w", "/en/static/57cc7b24023db7d15756c047a42894a1/e8613/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 1105w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57cc7b24023db7d15756c047a42894a1/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 230w", "/en/static/57cc7b24023db7d15756c047a42894a1/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 460w", "/en/static/57cc7b24023db7d15756c047a42894a1/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 920w", "/en/static/57cc7b24023db7d15756c047a42894a1/23725/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 1105w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/57cc7b24023db7d15756c047a42894a1/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dda835464c3439437ad991be5a68e691/3fca6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAyElEQVQY03WOUUoEMRBE5wK6ZthJujudTtIxUUQYhhnmR/cQewP99RLeQxA8qmz88GsfRX1VUTW01lS1tRZCuA9ecy6l1FpLKSLSWtv3fV1XVRWRGKOI1FpVlZmHZVnmed62jZlvbg8xRlUNIYgIc2BmRLQd17HWEhEAGGOGnLO1k50mcM4Yk7OqFiJyDgCgOyIiwL8QyXt/KZ9eX56fHqumnMQYI52/xDUReSK6lB8+fvD96/j27c6f5u6QUspZ+/JV+iM3juMv51AkKuoRFH4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dda835464c3439437ad991be5a68e691/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 230w", "/en/static/dda835464c3439437ad991be5a68e691/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 460w", "/en/static/dda835464c3439437ad991be5a68e691/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 920w", "/en/static/dda835464c3439437ad991be5a68e691/03ae8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 1112w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dda835464c3439437ad991be5a68e691/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 230w", "/en/static/dda835464c3439437ad991be5a68e691/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 460w", "/en/static/dda835464c3439437ad991be5a68e691/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 920w", "/en/static/dda835464c3439437ad991be5a68e691/01adf/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 1112w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dda835464c3439437ad991be5a68e691/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 230w", "/en/static/dda835464c3439437ad991be5a68e691/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 460w", "/en/static/dda835464c3439437ad991be5a68e691/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 920w", "/en/static/dda835464c3439437ad991be5a68e691/3fca6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 1112w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dda835464c3439437ad991be5a68e691/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Fill out the broker IP address (IP address of your camera), the broker port and login. Then click on `}<strong parentName="p">{`Create Thing`}</strong>{`. And the MQTT Binding is now sucessfully connected:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3dec8e822b0b284a5958b6cfe5b51374/0d6fe/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA6UlEQVQY003Iu07DMBiG4VxB6hx8Thw7rU9/YqoESAZEpa6IDYYucDWIpTeNGoTEs7yfvqxtWwDwzo1j0l13Dy44a63zG+dciHF5XH73f3feZrrTKaUQAgBwzmXTKqWEEJRSQgjbUErFhjF2ixRSSiGbzO/Nvms5rgSuOKnzfDcMwzgOKaV1XQEghGCMEUIopbz3SilOOa4xQih7OL+eXt7icu6PT61NVVlO0zTPc4wRAOym7/uuM8b0B3vQSqfnyR59sUNZc/l2n1f2/sUuV3L6KFGutdHacM4xJnhD/tyeGqughWkKVPwA0BIpwZFAwIkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3dec8e822b0b284a5958b6cfe5b51374/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 230w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 460w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 920w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/eec0b/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.avif 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3dec8e822b0b284a5958b6cfe5b51374/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 230w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 460w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 920w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/177a2/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.webp 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3dec8e822b0b284a5958b6cfe5b51374/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 230w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 460w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 920w", "/en/static/3dec8e822b0b284a5958b6cfe5b51374/0d6fe/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3dec8e822b0b284a5958b6cfe5b51374/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_07.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the Thing you created and switch to the `}<strong parentName="p">{`Channels`}</strong>{` tab and click on `}<strong parentName="p">{`Add Channel`}</strong>{` to configure data points (topic subscriptions) that you want to create:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/96df9a8715cbc3b81fb435f507e26e02/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABKUlEQVQY00WOz0rEMBCH+wTiNmnzZ5I0aZJJ03brUlxBDx4EDyKevCz4OB4UfGlNV/HjB5P5hmFSee9TSog4DFmDRGtM11lrTamFnIfbm+sYw7k128Bbk52pEPFwOCzLMs9z07S0aTnnQgjGmBACAKQQhrcgpQTgnDPGAIAxRpu2whis0axtBGesbep6l1Je1zXnHAuImCKmGDBGDKGYhIPWhtR1dffwdP/4jMsxzCt0fV3vAMBoLSWIM1yU9/adDVkcF4SQypw++rdP9vrOT1/N8YVcXihtAJRSukQbFfdqPGo//potAKos52k/zvs+xIBJAlBKnfPO9edY1/s0D8PicLJ/snjrKKXVNE3zNPa9C8FLKQkh6g9QqlNq1urK6FEro/4BgJ/L35sGNmJYYQF9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96df9a8715cbc3b81fb435f507e26e02/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 230w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 460w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 920w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/cb214/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.avif 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/96df9a8715cbc3b81fb435f507e26e02/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 230w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 460w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 920w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/180af/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.webp 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96df9a8715cbc3b81fb435f507e26e02/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 230w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 460w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 920w", "/en/static/96df9a8715cbc3b81fb435f507e26e02/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/96df9a8715cbc3b81fb435f507e26e02/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_08.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am going to use the `}<strong parentName="p">{`MQTT Alarmserver`}</strong>{` of my camera. This is a topic that is being updated every time an alarm is triggered. The payload carries the a numeric value representing the trigger that cause the alarm. If your camera is configured to use the `}<strong parentName="p">{`MQTT Prefix`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras`}</code>{` and the `}<strong parentName="p">{`MQTT ID`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`118`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/0d6fe/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABy0lEQVQoz2WOXW/SUACGSbxYENjaUihBTk9bYSvQZWGJ0gsqHR+tbSk7PR+0wHSbP2Au6Mx+gFcumfd6pS7+TkMlKvHJc/vkfTOGYbTbRqupH3ae+TcP49XD4PrH/wYffoY33xutI9cZ1RsNAICqqplWSlPXD4+Ok+u7xbt7dnX317epV58Wq/vF6nOtpqpQrVZrAEBFfZppp+i6brTbZPoyjgI8caKNY+SP1npDFPrTUyxy/ONsociLEGqKWv8nNowIkwgTytgsTiiLKZudvXp9cfnm/PwijhOEUEko7xX4Ii/Ksgqhtrn9O2aMzedzSimOIkIIQsh13WFKv993HKdSruzmOYErykCBUF0v/4kppXEcM8YopYSQ6XRq27ZlWb1ez7Is13UlUSrkdgWuCIAiy9sxY2y5XCZJQlLCMLSsnml2u6b5vNsdjkZSqZLPFgR+HQOgbMUY4/VnjKMowhgjhHw/8Hzf97zA8yZBUBalXDYv8OKTKpDl7ZgQQjcwSjBbXia3X2e3Xz7ixbfg9H2IcnvCzqMdsSjVYB1q+5u4mdI1TSfw7OFJ/+SFPbAHrjsMJv3R2Owcdw6axkGT4wSxJEGtAbV9RWv8AvEOkH+48rGmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 230w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 460w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 920w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/eec0b/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.avif 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 230w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 460w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 920w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/177a2/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.webp 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 230w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 460w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 920w", "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/0d6fe/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png 1115w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/50ff9bbbe3985ba538f5b2516c8b6fad/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_09.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then the alarmserver topic can be found under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cameras/118/status/alarm/triggered`}</code>{` (in the screenshot below I am using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT Explorer`}</a>{` to visualize the MQTT API):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/51ed8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABZElEQVQY01XNa07cMAAE4ByIYsd5Ll47dvzIxllsx0Cy5bkIVaU36LkogiXA3aosCLWj79dIo4loc5vTIaMDKDwoPTrqv5RszOnZR/+pmB0WPl70OTmJ9Pi0vprcdrLXL83mafjxdnr32m+nfjvZm5272dnrF+IeqH+g/g8Lj93FTo/Pm/vp/vd7ZI7PzfF5nHKU1vEeymqU7mUCZSIrlGoHf3q76r4z0dcqcBWY6NfuMiqKLPSuqghZHmVZkiAIwbcv4PAAIdiI2rVtq6TmXNd8xXnNqLVdlCbpxeWVdV4I2TQrjJcAQAjjTwCiJGFLyjDlpKoJ45RJwsiStMZESZIMw6CUxBgzxsqyBPv1RwAECCEhRWsMrSpazaGswhgbYyIIYQjBWss511ovFot/xxBChNC6W3vnlVRSyJmUXdeFEKIYoWHz0538YuIsz3P4fwCYn3WjtVbzZ0VpRRlnIfTjOP4FHcGCLfJC5rUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 230w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 460w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 920w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/14e47/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.avif 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 230w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 460w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 920w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/11b7f/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.webp 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 230w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 460w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 920w", "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/51ed8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e80767ed7fa1fc5ca756b1b315d59d2/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_10.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can add this topic as a trigger for our MQTT Binding `}<strong parentName="p">{`Channel`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1718c21af65ea408828520ba2b93b011/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABLUlEQVQY02WRS07DMBRFswHoi5vUnxfbL44/SZOqqdpZO2QPiCkD9sIEsWRwUiEQR3dwZfl4cF3EGL33KaUGm2BQI0qlELFZ0FofDodpHJu/rBeKeeF8PgshHgGI2ph67wNioxQqhZwLzuXa1yA2RC1jrLDW1nW923HOeVmWrvP7Yd91nVIo7ygppRDyB6UUEWX5erv1fR9CsNYCQHBunk/W0i6/J/gd8Te8qqosp9dP+fzOXz7E01u5eUhWp5jG/RhCDJnYto6oJWqtpZylSKmyPF6utD+56aL9ALDRxsaYqqoCAMbYdrtljJV32JJcGMvnxRB9R8Y7MhoBYBjG43GOMaXUd51fRtX/YqSUWZ6myTnnvTfGAIDW2jlnjNE6/8e60O+1lv1UXdXf8hfarThXGx2jEwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1718c21af65ea408828520ba2b93b011/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.avif 230w", "/en/static/1718c21af65ea408828520ba2b93b011/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.avif 460w", "/en/static/1718c21af65ea408828520ba2b93b011/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.avif 920w", "/en/static/1718c21af65ea408828520ba2b93b011/cb214/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.avif 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1718c21af65ea408828520ba2b93b011/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.webp 230w", "/en/static/1718c21af65ea408828520ba2b93b011/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.webp 460w", "/en/static/1718c21af65ea408828520ba2b93b011/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.webp 920w", "/en/static/1718c21af65ea408828520ba2b93b011/180af/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.webp 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1718c21af65ea408828520ba2b93b011/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png 230w", "/en/static/1718c21af65ea408828520ba2b93b011/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png 460w", "/en/static/1718c21af65ea408828520ba2b93b011/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png 920w", "/en/static/1718c21af65ea408828520ba2b93b011/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1718c21af65ea408828520ba2b93b011/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_11.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To be able to display the MQTT payload of our alarmserver topic in the OpenHAB UI we now need to create an `}<strong parentName="p">{`Item`}</strong>{` for it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a5ee42c3e586576838dc5ba58177070e/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABc0lEQVQoz3WQwW7UMBCG8wRo7d0465mxM3Ycr93EG1JUgaBqLz1z4YKQQOLGG3DmhODIE6Mk6qo99Nd3sEb+ZkZTMfM0TTnneZ4JkVEjAD4GAJzzKSXnHDyvA0BFRDnnGGNKqa7rnRAhBEQCQK2hbTml3PkOAC8gErMTQlTDWHK+Wn4jaYD9fj9Nrx07IkNkmRfZ+84Yu0FkmH3fRylldfPm+v72fblKfedaQ1JK7ztmZ4zVGsa1tT7Cpm2sXdpF9uVtvP7gxhtf3lGXpZTW2rZttQattXNuez8NEQHAIvOX3/HbX/Xp1/HzH3X3Ve5eed+dUrLW1nV9ONRr1FOUapRqFjn14TzkzrW9Z0O4rM1czuecs1JN0xxfYJVPKY+lhL4PfW+slVI67+PpxOy2IRdhG/iIWuRSyjzPwzCEEOwqExlEQqT1PITNQdcSG3U5GJEBWHashmEcxzHGuMlCCHmJELJu8OPP8P3f8eGH3O3k8/wHKq5Iub/6dbkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5ee42c3e586576838dc5ba58177070e/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.avif 230w", "/en/static/a5ee42c3e586576838dc5ba58177070e/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.avif 460w", "/en/static/a5ee42c3e586576838dc5ba58177070e/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.avif 920w", "/en/static/a5ee42c3e586576838dc5ba58177070e/cb214/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.avif 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a5ee42c3e586576838dc5ba58177070e/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.webp 230w", "/en/static/a5ee42c3e586576838dc5ba58177070e/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.webp 460w", "/en/static/a5ee42c3e586576838dc5ba58177070e/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.webp 920w", "/en/static/a5ee42c3e586576838dc5ba58177070e/180af/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.webp 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5ee42c3e586576838dc5ba58177070e/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png 230w", "/en/static/a5ee42c3e586576838dc5ba58177070e/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png 460w", "/en/static/a5ee42c3e586576838dc5ba58177070e/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png 920w", "/en/static/a5ee42c3e586576838dc5ba58177070e/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a5ee42c3e586576838dc5ba58177070e/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_12.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The now created `}<strong parentName="p">{`Item`}</strong>{` needs to be linked to our `}<strong parentName="p">{`Channel`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9215a22621920b243785f4a3b81954c/8cae4/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABLklEQVQY0zWQzU7DMBCE+wiNKYnttcdex46ThlaFGLX8nDgBVzggnoGH4NAHR0nFp9VqNNLMYVbTNO12u1IKs2dDgAVgFgB47+/2NzdDj4WLCaCFaR1WqcttTF3ulaZKCCklACJSShHR3KGk0doYczG11tbazXUtrjar3ZCNanS9Idms12sA24Wcc0rJe5+6ro2RmWedUs6Zmeu6rqpqdXr7Gp7f/fTi9k9VVTnnpmk6Ho+llHEcrbUhtMwMYNEhxgigaZo53H2f9cev/DzL1x8hhDY0lXJ6eCzl/nC4zbkPzM65pSI4z9555iClnMOHMUdH0RFbLSpBznbbvs8Dc4CF5+Bjj9BZOGtxOcBJqebwsB3bGGNKnlksgymll10unyJ8ckxE+h8iqutaCPEHey82+x+lNXsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9215a22621920b243785f4a3b81954c/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.avif 230w", "/en/static/b9215a22621920b243785f4a3b81954c/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.avif 460w", "/en/static/b9215a22621920b243785f4a3b81954c/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.avif 920w", "/en/static/b9215a22621920b243785f4a3b81954c/6885a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.avif 1109w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9215a22621920b243785f4a3b81954c/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.webp 230w", "/en/static/b9215a22621920b243785f4a3b81954c/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.webp 460w", "/en/static/b9215a22621920b243785f4a3b81954c/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.webp 920w", "/en/static/b9215a22621920b243785f4a3b81954c/71a33/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.webp 1109w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9215a22621920b243785f4a3b81954c/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png 230w", "/en/static/b9215a22621920b243785f4a3b81954c/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png 460w", "/en/static/b9215a22621920b243785f4a3b81954c/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png 920w", "/en/static/b9215a22621920b243785f4a3b81954c/8cae4/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png 1109w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9215a22621920b243785f4a3b81954c/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_13.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The `}<strong parentName="p">{`Item`}</strong>{` should be listed when clicking on `}<em parentName="p">{`Use an existing item`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b449cb786dd18e709c0bb3a9588b187/67fe0/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABuklEQVQoz12SQYsbIRTH8wFSkvGpoz5NHEeTyWR0JstuthSyFHoq7KGHQik99diP0VN77aGX/bRFpxu6/fEQEX48/08XKaWu61JKjXMbJTdKcs7rupZSKqWMMbtCY+18cgURFzGmIaZpOlnbLJfLrbXTOFlrtdZ1gRUAgP0DpbSqqsU+eMEZpyDruqrWPoSHh7eXy8P5fB7TOI5T3x9TGr0PTdM697e0NoSQxeXd+9Pryy7e7IYTUDp3qwhhjEmRI9ACewmlLMvx29P26+/6yy/16eeq1oetfnN/vr07T+NUQiIiKvV/SamyPAzDfBXnGgCoOffe7/edtY0QWZ/rqs1IKbN8PA7eh7b1zrUAtGbMaKONYTxP/VpCSkSNCrXWGrUQIsvjOIUQXIEQgoh583K2M/y64RwASuYY+7733jvnAEAI4XwwrjPtQdmQ76xQlpDyGaUU5zzLp2mKMbYFAuBQxv5wOPQh7BC1VlLRtYIVCq71BrVBNDqvGgAW3X4fQmjbtnSmrMwsPwZjUK2p8eLuUd5/YOEE1RqAwjO585x2lud/M0MIkNUr8Dfm43f7+Qe7fSSrJSnOlT8Z01THpwenqQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b449cb786dd18e709c0bb3a9588b187/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.avif 230w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.avif 460w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.avif 920w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/4293a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.avif 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b449cb786dd18e709c0bb3a9588b187/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.webp 230w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.webp 460w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.webp 920w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/41823/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.webp 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b449cb786dd18e709c0bb3a9588b187/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.png 230w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.png 460w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.png 920w", "/en/static/2b449cb786dd18e709c0bb3a9588b187/67fe0/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.png 1101w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b449cb786dd18e709c0bb3a9588b187/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_14.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now trigger an alarm on your camera and you should be able to see the value switch to, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` if you triggered `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
      }}>{`motion detection area 1`}</a>{` and then fall back to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{` - the idle position:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1ed6b252efa173470c38699e7c3ffacd/8cae4/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABYUlEQVQY002QXYucUAyG/QXFj9VRT3JyThJHj6Oug+N+TMu2hdKL3pey9P8s9KL/uejORV8CSUheEp4IEUWEiEQUjek8WUtEZN8TUQjh6eHheGzeW7sP1NGJKWLm8/k8z/OyLGVZ3uVFXdeIWO4yAGCMq0swW1lVVVmWCFBW1V1RRCJM1hZ5XuT5ociTOPbet23bNCoifpeoul3ee1VlZlPXcRxH1y/fvn7/EaZFw4RekiRp225d18vlMs9nVUW0bdveHra273vPUpOkSRLBrzf+/efw8+3w+rf49JrGH1j0+fn68vJ5XddxnFQb5/x21Xlyniwxq2v6zdwN8zAv/hikG4B8kiTT/f31+vHx8Wkcp5vHksUNFFoiS4HcETBLsyh07XDq2TsVBjBpmnLQYZyGYWSWjRdSbdmgA8D/I8uyqOu6EIKIqCoAZFkGiIgW0QKgAWAnJw2N1533zWnMtvkPRYxBJOEg6VwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ed6b252efa173470c38699e7c3ffacd/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.avif 230w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.avif 460w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.avif 920w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/6885a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.avif 1109w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1ed6b252efa173470c38699e7c3ffacd/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.webp 230w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.webp 460w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.webp 920w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/71a33/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.webp 1109w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ed6b252efa173470c38699e7c3ffacd/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.png 230w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.png 460w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.png 920w", "/en/static/1ed6b252efa173470c38699e7c3ffacd/8cae4/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.png 1109w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1ed6b252efa173470c38699e7c3ffacd/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_15.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To display this value on the OpenHab UI go to `}<strong parentName="p">{`Settings`}</strong>{`/`}<strong parentName="p">{`Pages`}</strong>{` select the `}<strong parentName="p">{`Overview`}</strong>{` page - `}<em parentName="p">{`for now`}</em>{` - , click on `}<strong parentName="p">{`Add Cells`}</strong>{` and choose a `}<strong parentName="p">{`Label Cell`}</strong>{` by clicking on the cell placeholder:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/14c0d40b157fdd874b530bc5fb84e342/76a99/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABOUlEQVQoz4XR224cIQwGYC6rHcDYxjAMM2SWkXYjJW2UZPsGvUjf/4WqgSaKcvwukGXxC8soERmbaZoYMYnnJnj2zEQkIZRSvGdEYmbvPRIFpuRZiUjOOTXGGGstNOTgRe93AGCtNdZqY1SMkZl71xijhyGEgIjOIQAw+3EcnXP4rNfMrLVWddtOp1NKqWVIay0iXjwxIyLRPiq+Q7TfVKNwHiWQi+QQ+vu6T9Fprc1HAEDJ+RLOv3G759MF0nE4HAa9G76mtbVWhYen+PjX/vzj7p7s1c1w+DF8m/wfH1QSmiIHNAEtwb7DPqd+9kVeTXnO8zKmSUJE4t41xvSzF5+Gc87zPMcYRQRx/555npdlKaXUWuu2lVKWxjn3ZnkqNNJQs65rrfW4XtXzbb3+dVzXY8PMAOBe+QeDXC7pgcBp1wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14c0d40b157fdd874b530bc5fb84e342/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.avif 230w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.avif 460w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.avif 920w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/88d8d/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.avif 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/14c0d40b157fdd874b530bc5fb84e342/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.webp 230w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.webp 460w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.webp 920w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/faa4b/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.webp 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14c0d40b157fdd874b530bc5fb84e342/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.png 230w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.png 460w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.png 920w", "/en/static/14c0d40b157fdd874b530bc5fb84e342/76a99/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.png 1117w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/14c0d40b157fdd874b530bc5fb84e342/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_16.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Configure the cell to use the alarmserver `}<strong parentName="p">{`Item`}</strong>{` you created:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/307eb26623055c9c0f94d7981d56496a/3fca6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.1304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABuklEQVQoz3WS3Y7cIAyFc50EkmD+bGwgyWbaVatV1XmF9mLf/4WqJBPNtFI/WWAQR9gHGmtteBBhGtFZAzvegrV2T7zPOZ/5yWSMB0ALjfeemVNKiNj3vVJqOJgGrfodrfW5v6P1MAxaa6W0UqoJ3htj9EHwHhGJCI9p27bb7cbMeMCEKYZxHM00AUDXdc26rtt2Q0RrrYiUUpmFWYRlnpd5Wc8ls2TJhDgMozGTMWYXo7MpugCjHXUiyrlcFgR/EF5wzp0Fn901/nb3t7tZf4zzB+WllPwo+z8opdq2Pb1o/M/PcP/UH7/V9180f8nCiBSvo/FFFhFjPMRd23Vd33UNOZM8+EnZoccYSqmIGP4lhhBjCOfN3UVDKSWWSARgSynv71/ndaO8sBTJNeVFpMhuXyappVTV908xH8QQAICIaq2JBWVGrpIL5cqSU0oRyeM+DsOgLprTUuccAORS3t42EUmUYsQj4iM53p9FpmmcLhp3AQCJKBEF77xzFuCvMMYCmGnaP9fFU2wBVsRvy1JCKD7UsMeMuIm8MW8se0nOdW377PkptlZYSp2vgh+BeD47EZK1rn0R/wGc4kqJ/w9USwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/307eb26623055c9c0f94d7981d56496a/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.avif 230w", "/en/static/307eb26623055c9c0f94d7981d56496a/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.avif 460w", "/en/static/307eb26623055c9c0f94d7981d56496a/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.avif 920w", "/en/static/307eb26623055c9c0f94d7981d56496a/03ae8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.avif 1112w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/307eb26623055c9c0f94d7981d56496a/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.webp 230w", "/en/static/307eb26623055c9c0f94d7981d56496a/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.webp 460w", "/en/static/307eb26623055c9c0f94d7981d56496a/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.webp 920w", "/en/static/307eb26623055c9c0f94d7981d56496a/01adf/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.webp 1112w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/307eb26623055c9c0f94d7981d56496a/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.png 230w", "/en/static/307eb26623055c9c0f94d7981d56496a/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.png 460w", "/en/static/307eb26623055c9c0f94d7981d56496a/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.png 920w", "/en/static/307eb26623055c9c0f94d7981d56496a/3fca6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.png 1112w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/307eb26623055c9c0f94d7981d56496a/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_17.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And the cell is now added to your Overview page:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d61c2ebb5d3054ed07988d277f3e892/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAArklEQVQY033NzQnCMBiA4d5FaNIc+vc1Jk1L0zZJJTagp0I9CYK6gXQBd3ABNxAHcEdxgbz3hzdgjFlrjTHOjQCglTJapUkcIUSwr5jgAACqumKMc87jJJF9X0kZErIKwzVCHhwiHPTqH6UUoKBZfh/UY9wubb1weqXgfePAOTdN02AMF0IU8Drsvqf5vbcf0zxbQSIv7rpeay2bpizFhhbHLL5Aeob0RvM5T/3nH04iJG6zbsKuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d61c2ebb5d3054ed07988d277f3e892/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.avif 230w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.avif 460w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.avif 920w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/cb214/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.avif 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d61c2ebb5d3054ed07988d277f3e892/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.webp 230w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.webp 460w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.webp 920w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/180af/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.webp 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d61c2ebb5d3054ed07988d277f3e892/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.png 230w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.png 460w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.png 920w", "/en/static/4d61c2ebb5d3054ed07988d277f3e892/cd536/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.png 1114w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d61c2ebb5d3054ed07988d277f3e892/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_18.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      